<template>
  <div class="overlay" v-if="overlay !== null && !overlay.blocked">
    <div class="overlay background"></div>
    <div class="overlay progress-bar" id="progress-bar" :style="{ backgroundImage: 'url(' + progressBarLogo + ')', width: `${progressBar}px` }"></div>
    <div class="overlay theme" id="theme" :style="{ backgroundImage: 'url(' + themeLogo + ')' }"></div>
    <div class="overlay campaign-logo" id="campaign-logo" v-html="campaignLogo"></div>
    <div class="overlay sponsor-logo" id="sponsor-logo" v-html="sponsorLogo"></div>
    <div class="overlay sponsor-level-logo" id="sponsor-level-logo" v-html="sponsorLevelLogo"></div>
    <div class="overlay sponsor-next-level-logo" id="sponsor-next-level-logo" v-html="sponsorNextLevelLogo"></div>
    <div class="level" id="level" v-text="levelText" :style="'color:' + (this.themeMode === '1' ? '#7d5bbe' : '#f0f0ff') + ' !important'" v-if="currentTarget !== 'COMPLETE'"></div>
    <div class="text" id="text" v-text="progressText" :style="'color:' + (this.isThemeHype ? '#7d5bbe' : '#f0f0ff') + ' !important'"></div>
    <div class="icon" id="icon" v-if="campaignExpired"><img src="/imgs/error-icon.png" width="50px" /></div>
    <div class="icon" id="icon" v-if="currentTarget === 'COMPLETE'"><img src="/imgs/confetti-icon.png" width="50px" /></div>
    <div class="overlay confetti" id="confetti-complete" v-if="currentTarget === 'COMPLETE' && showLevelConfetti === true"><img src="/imgs/celebration-confetti-complete.gif" width="750px" height="350px" /></div>
    <div class="overlay confetti" id="confetti" v-if="showLevelConfetti === true"><img src="/imgs/celebration-confetti.gif" width="750px" height="350px" /></div>
    <div class="sponsor-text" id="sponsor-text" v-text="sponsorText" :style="'color:' + (this.themeMode === '1' ? '#7d5bbe' : '#f0f0ff') + ' !important'"></div>
    <audio id="audio">
      <source src="/sounds/celebration-sound.wav" />
    </audio>
    <template v-if="!campaignExpired">
      <horizontal-auto-scroll :themeMode="Number(themeMode)" :messages="messages" :messagesInterval="messagesInterval" :messagesDuration="messagesDuration" :show="show"></horizontal-auto-scroll>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HorizontalAutoScroll from '../components/HorizontalAutoScroll.vue'

export default {
  components: {
    HorizontalAutoScroll
  },
  data() {
    return {
      themeMode: 0,
      level: 0,
      showLevelConfetti: false,
      audioPlaying: false,
      messages: [],
      messagesInterval: 0,
      messagesDuration: 0,
      show: true,
      hasOverlayMessage: false,
      turnOnId: null,
      turnOffId: null
    }
  },
  computed: {
    ...mapGetters(['overlay']),
    campaignExpired() {
      return new Date() > new Date(this.overlay.campaign.end_date)
    },
    currentTarget() {
      for (let i = 0; i < this.overlay.campaign.tiers.length; i++) {
        if (this.overlay.score < this.overlay.campaign.tiers[i]) return this.overlay.campaign.tiers[i]
      }
      return 'COMPLETE'
    },
    currentTargetLevel() {
      for (let i = 0; i < this.overlay.campaign.tiers.length; i++) {
        if (this.overlay.score < this.overlay.campaign.tiers[i]) return i + 1
      }
      return 'All'
    },
    isThemeHype() {
      return this.overlay.campaign.overlay_theme_image_url?.includes('hype') || this.overlay.campaign.overlay_theme_light_image_url?.includes('hype')
    },
    themeLogo() {
      var img = `/imgs/theme-purple${this.hasLevelNaming() ? '-logo' : ''}.png`
      if (Number(this.themeMode) === 1) {
        if (this.overlay.campaign.overlay_theme_light_image_url) {
          img = `/imgs/${this.overlay.campaign.overlay_theme_light_image_url}`
          if (this.hasLevelNaming()) {
            img = img.split('.png')[0] + '-logo.png'
          }
        }
      } else {
        if (this.overlay.campaign.overlay_theme_image_url) {
          img = `/imgs/${this.overlay.campaign.overlay_theme_image_url}`
          if (this.hasLevelNaming()) {
            img = img.split('.png')[0] + '-logo.png'
          }
        }
      }
      return img
    },
    levelText() {
      if (this.campaignExpired) {
        return ''
      }
      return `${this.$vuetify.lang.t('$vuetify.level')} ${this.currentTargetLevel - 1}`
    },
    progressText() {
      if (this.campaignExpired) {
        return this.$vuetify.lang.t('$vuetify.campaign_expired')
      }
      const target = this.currentTarget
      if (target === 'COMPLETE') {
        return `${this.$vuetify.lang.t('$vuetify.congratulations')} ${this.$vuetify.lang.t('$vuetify.challenge_completed')}`
      }
      return `${this.overlay.score} / ${target} EXP > ${this.$vuetify.lang.t('$vuetify.level')} ${this.currentTargetLevel}`
    },
    progressPercentage() {
      if (this.overlay.score === 0) return 0
      const target = this.currentTarget
      if (target === 'COMPLETE') {
        return 100
      }
      return 100 - (this.overlay.score / this.currentTarget) * 100
    },
    progressBar() {
      if (this.campaignExpired) {
        return 0
      }
      var progressBar = 669
      if (this.currentTarget === 'COMPLETE') {
        progressBar = 669
      } else {
        progressBar = 0 + 669 * (this.overlay.score / this.currentTarget)
      }
      return progressBar
    },
    progressBarLogo() {
      var img = '/imgs/progress-blue.png'
      if (Number(this.themeMode) === 1) {
        if (this.overlay.campaign.progress_bar_light_image_url) {
          img = `/imgs/${this.overlay.campaign.progress_bar_light_image_url}`
        }
      } else {
        if (this.overlay.campaign.progress_bar_image_url) {
          img = `/imgs/${this.overlay.campaign.progress_bar_image_url}`
        }
      }
      return img
    },
    campaignLogo() {
      var img = ''
      if (Number(this.themeMode) === 1) {
        if (this.overlay.campaign.logo_light_image_url) {
          img = `<img src="${this.overlay.campaign.logo_light_image_url}" width="278px" height="42px" />`
        }
      } else {
        if (this.overlay.campaign.logo_image_url) {
          img = `<img src="${this.overlay.campaign.logo_image_url}" width="278px" height="42px" />`
        }
      }
      return img
    },
    sponsorText() {
      return this.overlay.campaign?.sponsor_text ?? ''
    },
    sponsorLogo() {
      var img = ''
      if (Number(this.themeMode) === 1) {
        if (this.overlay.campaign.sponsor_light_image_url) {
          img = `<img src="${this.overlay.campaign.sponsor_light_image_url}" width="168px" height="42px" />`
        }
      } else {
        if (this.overlay.campaign.sponsor_image_url) {
          img = `<img src="${this.overlay.campaign.sponsor_image_url}" width="168px" height="42px" />`
        }
      }
      return img
    },
    sponsorLevelLogo() {
      var img = ''
      var sponsorLevelImageUrl = `sponsor_level${this.currentTargetLevel - 1}_image_url`
      var sponsorLevelName = `sponsor_level${this.currentTargetLevel - 1}_name`
      if (this.overlay.campaign[sponsorLevelImageUrl]) {
        img = `<img src="${this.overlay.campaign[sponsorLevelImageUrl]}" width="190px" height="45px" />`
      }
      if (this.overlay.campaign[sponsorLevelName]) {
        if (Number(this.themeMode) === 1) {
          img = `<span style="color: #7d5bbe; font-family: 'RoobertRegular', sans-serif; font-size: 30px; line-height: 30px; text-transform: uppercase; text-align: left; z-index: 400; font-weight: 800; position: absolute; top: 3px;">${this.overlay.campaign[sponsorLevelName]}</span>`
        } else {
          img = `<span style="color: #f0f0ff; font-family: 'RoobertRegular', sans-serif; font-size: 30px; line-height: 30px; text-transform: uppercase; text-align: left; z-index: 400; font-weight: 800; position: absolute; top: 3px;">${this.overlay.campaign[sponsorLevelName]}</span>`
        }
      }
      return img
    },
    sponsorNextLevelLogo() {
      var img = ''
      var sponsorLevelImageUrl = `sponsor_level${this.currentTargetLevel}_image_url`
      var sponsorLevelName = `sponsor_level${this.currentTargetLevel}_name`
      if (this.overlay.campaign[sponsorLevelImageUrl]) {
        img = `<img src="${this.overlay.campaign[sponsorLevelImageUrl]}" width="250px" height="56px" />`
      }
      if (this.overlay.campaign[sponsorLevelName]) {
        if (Number(this.themeMode) === 1) {
          img = `<span style="color: #7d5bbe; font-family: 'RoobertRegular', sans-serif; font-size: 30px; line-height: 30px; text-transform: uppercase; text-align: left; z-index: 400; font-weight: 800; position: absolute; top: 11px; left: 141px">${this.overlay.campaign[sponsorLevelName]}</span>`
        } else {
          img = `<span style="color: #f0f0ff; font-family: 'RoobertRegular', sans-serif; font-size: 30px; line-height: 30px; text-transform: uppercase; text-align: left; z-index: 400; font-weight: 800; position: absolute; top: 11px; left: 141px">${this.overlay.campaign[sponsorLevelName]}</span>`
        }
      }
      return img
    }
  },
  methods: {
    ...mapActions(['loadOverlay']),
    hasLevelNaming() {
      var sponsorLevelImageUrl = `sponsor_level${this.currentTargetLevel - 1}_image_url`
      var sponsorLevelName = `sponsor_level${this.currentTargetLevel - 1}_name`
      return this.overlay.campaign[sponsorLevelImageUrl] || this.overlay.campaign[sponsorLevelName]
    },
    updateLevel(newLevel) {
      this.level = newLevel
    },
    toggleShowLevelConfetti() {
      this.showLevelConfetti = !this.showLevelConfetti
    },
    timeoutConfetti() {
      setTimeout(() => {
        this.showLevelConfetti = false
      }, 20000)
    },
    playSound() {
      if (this.showLevelConfetti && !this.audioPlaying) {
        this.audioPlaying = true
        var audio = document.getElementById('audio')
        audio.play()
        setTimeout(() => {
          audio.pause()
        }, 20000)
      }
    },
    messageStarted() {
      return new Date() > new Date(this.overlay.campaign.overlay_message_start_date)
    },
    messageExpired() {
      return new Date() > new Date(this.overlay.campaign.overlay_message_end_date)
    },
    turnOn() {
      clearTimeout(this.turnOnId)
      this.turnOnId = setTimeout(() => {
        if ((this.hasOverlayMessage && this.messageStarted() && !this.messageExpired()) || this.messages.length > 0) {
          this.show = true
          this.turnOn()
        } else {
          this.show = false
          this.turnOff()
        }
      }, this.messagesInterval * 60 * 1000 + 10)
    },
    turnOff() {
      clearTimeout(this.turnOffId)
      this.turnOffId = setTimeout(() => {
        this.show = false
        this.turnOff()
      }, this.messagesDuration * 60 * 1000 - 10)
    },
    overlayMessages() {
      this.messages = []
      const message = this.overlay.campaign.overlay_message_text
      if (message && this.messageStarted() && !this.messageExpired()) {
        this.messages.push(message)
        this.hasOverlayMessage = true
        this.messagesInterval = this.overlay.campaign.overlay_message_interval || 5
        this.messagesDuration = this.overlay.campaign.overlay_message_duration || 1
      } else {
        this.hasOverlayMessage = false
        this.messagesInterval = this.overlay.campaign.overlay_message_interval || 30
        this.messagesDuration = this.overlay.campaign.overlay_message_duration || 1
      }
      if (this.overlay.coupons !== null && this.overlay.coupons.length > 0 && this.overlay.campaign.display_coupons_enabled === true) {
        this.overlay.coupons.forEach((coupon) => {
          const text = `${coupon.code}: ${coupon.mechanics}`
          this.messages.push(text)
        })
      }
      if (this.messages.length > 0) {
        if (!this.show && this.turnOnId === null) {
          this.show = true
          this.turnOn()
          this.turnOff()
        }
      } else {
        this.show = false
      }
    },
    levelCompleted() {
      this.overlayMessages()
      if (this.currentTarget === 'COMPLETE') {
        this.showLevelConfetti = true
        this.playSound()
        this.timeoutConfetti()
      } else {
        const currentLevel = this.currentTargetLevel - 1 ?? 0
        const isLevelCompleted = this.level < currentLevel
        if (isLevelCompleted) {
          this.showLevelConfetti = true
          this.updateLevel(currentLevel)
          this.timeoutConfetti()
        }
      }
    }
  },
  mounted() {
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    this.themeMode = this.$route.params.theme
    this.$vuetify.lang.current = localStorage.language ?? 'en'
    this.queueInterval = setInterval(() => {
      this.$vuetify.lang.current = localStorage.language ?? 'en'
      this.loadOverlay({ campaign_id: this.$route.params.campaignId, channel_id: this.$route.params.channelId, slug: this.$route.params.slug }).then(() => this.levelCompleted())
    }, 10000)
    this.loadOverlay({ campaign_id: this.$route.params.campaignId, channel_id: this.$route.params.channelId, slug: this.$route.params.slug }).then(() => this.levelCompleted())
  }
}
</script>
<style>
html {
  overflow: hidden;
}
body {
  background: transparent !important;
}
</style>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  height: 280px;
  width: 750px;
  background-repeat: no-repeat;
}
.background {
  z-index: 100;
  background-image: url('/imgs/background.png');
}
.progress-bar {
  z-index: 200;
}
.theme {
  z-index: 300;
  top: 80px;
  width: 746px;
}
.level {
  color: #f0f0ff;
  font-family: 'RoobertRegular', sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 800;
  position: absolute;
  z-index: 400;
  top: 90px;
  left: 98px;
  text-align: left;
  width: 125px;
}
.text {
  color: #f0f0ff;
  font-family: 'RoobertRegular', sans-serif;
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: left;
  z-index: 400;
  font-weight: 800;
  position: absolute;
  top: 141px;
  left: 36px;
  width: 610px;
}
.icon {
  z-index: 400;
  position: absolute;
  top: 132px;
  left: 24px;
}
.confetti {
  z-index: 1000;
}
.progress-outter {
  background-image: linear-gradient(to right, #9146ff, #06efe8);
  width: calc(100% - 2px);
}
.progress {
  background-color: #000000;
  height: 30px;
  padding: 0;
  left: 0;
  position: fixed;
}
.campaign-logo {
  z-index: 300;
  top: 200px;
  left: 90px;
}
.sponsor-text {
  color: #f0f0ff;
  font-family: 'RoobertRegular', sans-serif;
  font-size: 22px;
  text-align: right;
  z-index: 400;
  position: absolute;
  top: 195px;
  left: 380px;
  width: 135px;
  height: 60px;
}
.sponsor-logo {
  z-index: 300;
  top: 200px;
  left: 530px;
}

.sponsor-level-logo {
  z-index: 300;
  top: 82px;
  left: 196px;
}
.sponsor-next-level-logo {
  z-index: 300;
  top: 131px;
  left: 390px;
}
</style>
